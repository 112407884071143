import {BrowserRouter,Route,Routes,useParams,useSearchParams} from 'react-router-dom'
import {UserProvider} from './contexts/UserContext'
import {ErrorProvider} from './contexts/ErrorContext'
import {useState,useEffect,useRef} from 'react'
import {getAuth} from 'firebase/auth'
import firebase from './helpers/firebase'
import Icon from './components/Text/Icon'
import Text from './components/Text/Text'
import {useAuthState} from 'react-firebase-hooks/auth'
import Login from './pages/Auth/Login'
import Main from './pages/Dashboard/Main'
import Design from './pages/Dashboard/Design'
import Settings from './pages/Dashboard/Settings/Settings'
import Projects from './pages/Dashboard/Projects'
import Authenticated from './layouts/Authenticated'
import Guest from './layouts/Guest'
import DeleteAccount from './pages/DeleteAccount'
import Pricing from './pages/Checkout/Pricing'
import Error from './pages/Errors/Error'
import NotFound from './pages/Errors/NotFound'
import Tos from './pages/Legal/Tos'
import Privacy from './pages/Legal/Privacy'
import Landing from './pages/Landing/Landing'
import Verify from './pages/Auth/Verify'
import LinkedInTag from 'react-linkedin-insight'
import UserSurveyOne from './pages/Forms/UserSurveyOne'
import Experience from './pages/Forms/Experience'
import Unsubscribe from './pages/Legal/Unsubscribe'
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import GenerateFromExtension from './pages/Landing/GenerateFromExtension'
import Embed from './pages/Dashboard/Embed'
import {logEvent} from 'firebase/analytics'
import analytics from './helpers/analytics'


function App() {
  const app=firebase
  const auth=getAuth()
  const [user,loading,authError]=useAuthState(auth)
  const [error,setError]=useState('')
  const [urlSearchParams,setUrlSetParams]=useSearchParams()

  useEffect(() => {
    LinkedInTag.init(6796737,'px',false)
    if(urlSearchParams.get('purchase_success')==='true') {
      logEvent(analytics,'purchase',{
        transaction_id: decodeURIComponent(urlSearchParams.get('uid'))+decodeURIComponent(urlSearchParams.get('purchase_date')),
        value: Number(decodeURIComponent(urlSearchParams.get('purchase_value'))),
        currency: urlSearchParams.get('purchase_currency'),
        items: [{
          item_name: decodeURIComponent(urlSearchParams.get('purchase_item')),
          price: Number(urlSearchParams.get('purchase_price')),
          item_variant: decodeURIComponent(urlSearchParams.get('purchase_variant'))
        }]
      })
    }
  },[urlSearchParams])



  return (
    <ErrorProvider value={setError}>
      <UserProvider value={[user,loading,authError]}>
        {error.length>0&&<div className={`fixed flex items-center justify-start rounded-lg shadow-md bottom-5 right-2 left-2 bg-light lg:top-4 lg:bottom-auto lg:right-4 lg:left-auto z-50 transition-position ${error.length>0? 'bottom-5 lg:right-4':'-bottom-64 lg:-right-64'}`}>
          <div className='h-full px-4 py-2 rounded-l-lg bg-danger'>
            <Icon icon='exclamation-circle-fill' className='inline text-xl  !px-0 text-light' />
          </div>
          <Text className='inline pl-3 pr-4'>
            {error}
          </Text>
          <div className='m-2' onClick={() => {
            setError('')
          }}>
            <Icon icon='x' className='text-lg cursor-pointer' />
          </div>
        </div>}
        <div className="w-full min-h-screen App bg-light no-scrollbar">
          <Routes>
            <Route path='/error' element={<Error />} />
            <Route element={<Authenticated />} >
              <Route path='/dashboard' element={<Main />}>
                <Route path='/dashboard/design/public/:uid/:projectId' element={<Design readOnly />} />
                <Route path='/dashboard/design/:uid/:projectId' element={<Design />} />
                <Route path='/dashboard/design/:projectId' element={<Design />} />
                <Route path='/dashboard/' element={<Projects />} />
                <Route path='/dashboard/settings' element={<Settings />} />
                <Route path='/dashboard/generate-from-extension' element={<GenerateFromExtension />} />
              </Route>
              <Route path='/delete/:uid' element={<DeleteAccount />} />
              <Route path='/unsubscribe' element={<Unsubscribe />} />
              <Route path='/forms/user-survey' element={<UserSurveyOne />} />
              <Route path='/forms/experience' element={<Experience />} />
            </Route>
            <Route element={<Guest />} >
              <Route path='/login' element={<Login />} />
              <Route path='/login/:redirect' element={<Login />} />
              <Route path='/verify' element={<Verify />} />
              <Route path='/verify/:redirect' element={<Verify />} />
            </Route>
            <Route element={<Guest accessible />}>
              <Route path='/' element={<Landing />} />
              <Route path='/pricing' element={<Pricing />} />
              <Route path='/terms' element={<Tos />} />
              <Route path='/privacy' element={<Privacy />} />
            </Route>
            <Route path='/embed/:uid/:projectId' element={<Embed />} />
            <Route path='*' element={<NotFound />} />
          </Routes>
        </div>
      </UserProvider>
    </ErrorProvider>
  )
}

export default App;
